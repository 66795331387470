import React from "react"
import "twin.macro"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// Components
import Layout from "@components/layout"
import Container from "@components/container"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import SubscribeForm from "@components/subscribeForm"
import { sectionMd } from "@styles/ui"

import { RECAPTCHA_3_KEY } from "@utils/constants"

const StayConnected = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Stay Connected for XYWAV® Narcolepsy Information | XYWAV®"
        description="Sign up to receive helpful information about narcolepsy and XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution via email. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero
        eyebrow="Sign Me Up"
        footnotes={
          <span tw="text-base font-black lg:(max-w-[915px] inline-block)">
            Sign up to hear from real XYWAV patients on their treatment
            experience, get expert perspectives on living with and managing
            narcolepsy, and learn about JazzCares<sup>&reg;</sup> financial
            support. Get all that and more by signing up below.
          </span>
        }
        gradient="purple-red"
      >
        <>More Resources Await You</>
      </StaticHero>
      <section css={sectionMd}>
        <Container>
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_3_KEY}>
            <SubscribeForm />
          </GoogleReCaptchaProvider>
        </Container>
      </section>
    </Layout>
  )
}

export default StayConnected
